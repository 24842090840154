/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "size_default": "px-4 gap-9 md:gap-16 mt-3 md:mt-7 mb-6 md:mb-10 [&>*:first-child]:-mb-3 md:[&>*:first-child]:-mb-6",
  "variant_default": "flex flex-col",
  "colors_default": ""
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "VideoBaseContent");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;