import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { VideoBaseContent } from './VideoBaseContent';

export interface StandaloneVideoBaseContentProps extends StandaloneComponentProps<typeof VideoBaseContent> {
  children?: React.ReactNode;
}

export const StandaloneVideoBaseContent: StandaloneComponent<StandaloneVideoBaseContentProps> = ({
  options,
  children,
  ...props
}) => {
  const { ...$base } = { ...options };

  return (
    <VideoBaseContent {...$base} {...props}>
      {children}
    </VideoBaseContent>
  );
};

export default StandaloneVideoBaseContent;
