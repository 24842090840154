/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneVideoBaseContent as ResolvedStandaloneVideoBaseContent } from 'base/components/standalone/VideoBaseContent';

export const VideoBaseContent = ResolvedStandaloneVideoBaseContent;

export type VideoBaseContentProps = PropsFromComponent<typeof VideoBaseContent>;

export const VideoBaseContentWith = (extras: DynamicStandaloneExtras): typeof VideoBaseContent => {
    return function VideoBaseContent(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'VideoBaseContent');
        return <ResolvedStandaloneVideoBaseContent {...mergeProps({ options: { theme } }, props)} />;
    }
}